import React from 'react';
import tw from 'twin.macro';
import CareersCarouselImage from './CareersCarouselImage';
import { graphql, useStaticQuery } from 'gatsby';
import CareerHeroImages from './component/CareerHeroImages';
import { VIEW_OPENING_URL } from './constant';
import { useIntl } from 'react-intl';

const HeroWrapper = tw.div`
  px-[30px] pb-[30px] pt-[60px] lg:px-0 text-base_text mt-[84px] flex flex-col justify-center items-center
`;
const Heading = tw.h3`
  text-[36px] md:text-[48px] m-0
`;
const SubHeading = tw.p`
  mb-10
`;
const SubFooting = tw.h1`
  mt-5 text-[20px]
`;
const ViewButton = tw.a`
  mt-4 border border-hubble_blue bg-white px-6 py-2 text-hubble_blue hover:text-white hover:bg-hubble_blue text-[20px] lg:text-[24px]
`;
const PICTURE_PER_CAROUSEL = 4;
const CareersHero = () => {
  const { formatMessage } = useIntl();

  const data = useStaticQuery(graphql`
    query {
      allStrapiCareer {
        nodes {
          HeroCarousel {
            url
            alternativeText
          }
          PageHeader
          PageSubHeader
          HeroBannerDescription
        }
      }
    }
  `);
  const { PageHeader, PageSubHeader, HeroBannerDescription, HeroCarousel } =
    data.allStrapiCareer.nodes[0];
  let newHeroList = HeroCarousel;
  const numExtraImages = HeroCarousel.length % PICTURE_PER_CAROUSEL;
  if (HeroCarousel.length > PICTURE_PER_CAROUSEL && numExtraImages > 0) {
    newHeroList = [
      ...HeroCarousel,
      ...HeroCarousel.slice(0, PICTURE_PER_CAROUSEL - numExtraImages),
    ];
  }

  return (
    <HeroWrapper>
      <Heading>{PageHeader}</Heading>
      <SubHeading className="b5">{PageSubHeader}</SubHeading>
      {newHeroList.length > 4 ? (
        <CareersCarouselImage imageList={newHeroList} />
      ) : (
        <CareerHeroImages imageList={newHeroList} />
      )}
      <SubFooting className="b5">{HeroBannerDescription}</SubFooting>
      <ViewButton target="_blank" href={VIEW_OPENING_URL}>
        {formatMessage({ id: 'career.button.viewOpenings' })}
      </ViewButton>
    </HeroWrapper>
  );
};

export default CareersHero;
