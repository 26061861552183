import NewLayout from 'components/NewLayout';
import GrantArticleIndex from 'components/pages/grantArticle';
import SEO from 'components/SEO';
import { graphql, HeadFC } from 'gatsby';
import LanguageProvider from 'LanguageProvider/index';
import React from 'react';
import translation from 'intl';
import { PREFIX_CANONICAL_URL } from 'components/constant';

export interface GrantArticlePageContext {
  id: string;
  title: string;
  description: string;
  cover: string;
  slug: string;
  seo: {
    metaDescription: string;
    metaTitle: string;
    metaSocial: {
      title: string;
      description: string;
      image: { url: string };
    };
    preventIndexing: boolean;
    canonicalURL: string;
  };
}

interface Props {
  data: {
    allStrapiGrantArticle: {
      nodes: any[];
    };
  };
  pageContext: GrantArticlePageContext;
}

const GrantArticleTemplate = ({ data, pageContext: { slug } }: Props) => {
  const url = `${PREFIX_CANONICAL_URL}/grants/${slug}`;

  return (
    <LanguageProvider messages={translation}>
      <NewLayout headerStyle="transparent">
        <GrantArticleIndex
          data={data.allStrapiGrantArticle.nodes[0]}
          pageUrl={url}
        />
      </NewLayout>
    </LanguageProvider>
  );
};

export default GrantArticleTemplate;

export const query = graphql`
  query ($id: Int) {
    allStrapiGrantArticle(filter: { strapi_id: { eq: $id } }) {
      nodes {
        strapi_id
        title
        subHeading
        cover {
          url
        }
        eligibility {
          description
        }
        details {
          description
          id
          title
        }
        benefitsDescription
        grantBenefitsModule {
          id
          buttonLabel
          buttonLink
          description {
            data {
              description
            }
          }
          label
          title
          coverAlternativeText
          coverUrl
        }
        applicationStep {
          id
          title
          description {
            data {
              description
            }
          }
        }
        PageCTASubHeader
        PageCTAHeader
        PageCTAEmphaticDescription
        PageCTADescription
        PageCTAButtonLink
        PageCTAButtonLabel
        RequestForDemoImage {
          url
          alternativeText
        }
        related_articles {
          title
          publishedAt
          cover {
            alternativeText
            url
            formats {
              small {
                url
              }
            }
          }
          category {
            name
          }
          description
          slug
          readingTime {
            text
          }
        }
      }
    }
  }
`;

export const Head = ({ pageContext: { cover, seo, slug } }: HeadFC & Props) => {
  const url = `${PREFIX_CANONICAL_URL}/grants/${slug}`;
  const { metaDescription, metaTitle, metaSocial, preventIndexing } = seo;

  if (!metaSocial)
    return (
      <SEO
        pageTitle={metaTitle}
        description={metaDescription}
        ogImg={cover}
        preventIndexing={preventIndexing}
        canonicalURL={url}
      />
    );
  const { title, description, image } = metaSocial;
  return (
    <SEO
      pageTitle={metaTitle}
      description={metaDescription}
      ogImg={image.url}
      ogTitle={title}
      ogDescription={description}
      isArticle={true}
      preventIndexing={preventIndexing}
      canonicalURL={url}
    />
  );
};
