import LanguageProvider from 'LanguageProvider/index';
import React from 'react';
import GrantArticleHero from './GrantArticleHero';
import translation from './translation';
import LayoutContainer from 'components/LayoutContainer';
import './style.css';
import GrantArticleDetails from './GrantArticleDetails';
import GrantArticleBenefits from './benefits';
import GrantArticleEligibility from './GrantArticleEligibility';
import GrantArticleApplication from './GrantArticleApplication';
import { BenefitsContentType, GrantArticleRequestType } from './type';
import GrantArticleRequestForDemo from './GrantArticleRequestForDemo';
import StructuredDataScript from 'components/structuredData/StructuredDataScript';
import { GrantsArticleStructuredDataType } from 'components/structuredData/types';
import { getFirstElementOfBreadcrumbList } from 'components/structuredData/structuredDataUtils';
import { useIntl } from 'react-intl';
import {
  PREFIX_CANONICAL_URL,
  StructuredDataTypes,
  STRUCTURED_DATA_CONTEXT,
} from 'components/constant';
import ExploreRelatedArticle from 'components/exploreRelatedArticles';
import { ArticleCardType } from 'components/types';

interface GrantArticleIndexProps {
  data: {
    [key in string]?: any;
  };
  pageUrl: string;
}

const GrantArticleIndex = ({ data, pageUrl }: GrantArticleIndexProps) => {
  const { formatMessage } = useIntl();

  const {
    title,
    subHeading,
    cover: { url: heroCover },
    details,
    eligibility,
    applicationStep,
    benefitsDescription,
    grantBenefitsModule,
    PageCTAButtonLabel,
    PageCTAButtonLink,
    PageCTADescription,
    PageCTAEmphaticDescription,
    PageCTAHeader,
    PageCTASubHeader,
    RequestForDemoImage,
    related_articles: relatedArticles,
  } = data;
  const heroContent = {
    title,
    subHeading,
  };
  const eligibilityList = (eligibility as { description: string }[]).map(
    ({ description }) => description,
  );

  const benefitsContent: BenefitsContentType = {
    subText: benefitsDescription,
    list: grantBenefitsModule.map(
      ({
        coverAlternativeText,
        coverUrl,
        description,
        ...module
      }: Record<string, any>) => ({
        ...module,
        description: description.data.description,
        imgSrc: coverUrl,
        imgAlt: coverAlternativeText,
      }),
    ),
  };
  const requestDemoContent: GrantArticleRequestType = {
    subHeader: PageCTASubHeader,
    header: PageCTAHeader,
    strongDescription: PageCTAEmphaticDescription,
    description: PageCTADescription,
    btnLabel: PageCTAButtonLabel,
    btnLink: PageCTAButtonLink,
    imgSrc: RequestForDemoImage.url,
    imgAlt: RequestForDemoImage.alternativeText,
  };

  const structuredData: GrantsArticleStructuredDataType = {
    '@context': STRUCTURED_DATA_CONTEXT,
    '@type': StructuredDataTypes.BREADCRUMB_LIST,
    '@id': `${pageUrl}#breadcrumb`,
    itemListElement: [
      getFirstElementOfBreadcrumbList(formatMessage),
      {
        '@type': StructuredDataTypes.LIST_ITEM,
        position: 2,
        name: formatMessage({ id: 'structuredData.grants' }),
        item: `${PREFIX_CANONICAL_URL}/grants`,
      },
      {
        '@type': StructuredDataTypes.LIST_ITEM,
        position: 3,
        name: title,
      },
    ],
  };
  return (
    <LanguageProvider messages={translation}>
      <StructuredDataScript data={structuredData} />
      <div
        style={{
          background: `url(${heroCover}) no-repeat center center /cover`,
        }}
        className="grant-article-hero-background pt-[84px] min-h-[345px] h-fit relative"
      >
        <LayoutContainer>
          <GrantArticleHero {...heroContent} />
        </LayoutContainer>
      </div>
      <LayoutContainer>
        <GrantArticleDetails detailsList={details} />
      </LayoutContainer>
      <div className="bg-accent_grey_1">
        <LayoutContainer>
          <GrantArticleBenefits {...benefitsContent} />
        </LayoutContainer>
      </div>
      <LayoutContainer>
        <GrantArticleEligibility list={eligibilityList} />
        <GrantArticleApplication listOfStep={applicationStep} />
      </LayoutContainer>
      <div className="bg-accent_grey_1">
        <LayoutContainer>
          <GrantArticleRequestForDemo {...requestDemoContent} />
        </LayoutContainer>
      </div>
      <LayoutContainer>
        <ExploreRelatedArticle
          exploreTitle={formatMessage({
            id: 'article.explore.title',
          })}
          exploreDescription={formatMessage({
            id: 'article.explore.description',
          })}
          id="newsroom"
          articleList={relatedArticles as ArticleCardType[]}
        />
      </LayoutContainer>
    </LanguageProvider>
  );
};

export default GrantArticleIndex;
