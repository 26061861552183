import { SortType } from 'components/filter/type';

export const ACCEPTED_FILE_TYPES: string[] = [
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
  'application/pdf',
];
export const FILE_SIZE_LIMIT = 1024 * 1024 * 5; // 5MB

export const CAREER_SORT_CATEGORY = {
  RECENT: 'recent',
  ABC: 'abc',
} as const;

export const CAREERS_SORT: SortType = {
  key: 'sort',
  label: 'Sort',
  items: [
    { value: CAREER_SORT_CATEGORY.RECENT, label: 'Recently uploaded' },
    { value: CAREER_SORT_CATEGORY.ABC, label: 'Alphabetical' },
  ],
};

export const JOBS_PER_PAGE = 10;

export const VIEW_OPENING_URL = 'https://hubble.breezy.hr/';
